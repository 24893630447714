nav {
    position: sticky;
    top: 0;
    z-index: 1000; 
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  .navbar {
    width: 100%;
    position: relative;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    background: none;
    padding: 16px 0;
  }
  
  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav-links a {
    color: rgba(17, 41, 90, 1);
    text-decoration: none;
  }

  .nav-links a:hover {
    color: #2a91ff;
  }
  .active-link{
    color:#2a91ff !important
  }

  .nav-links {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 32px;
    align-items: center;
  }
  
  .nav-links.open {
    display: block;
  }
  
  .nav-links li {
    color: #11295a;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.78px;
  }
  
  .menu-icon {
    cursor: pointer;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 24px;
  }
  
  .bar {
    background-color: #11295a;
    width: 48px;
    height: 3px;
    border-radius: 3px 0px 0px 0px;
    transition: transform 0.3s ease-in-out;
  }
  
  .bar-2 {
    width: 40px;
    height: 3px;
    border-radius: 3px 0px 0px 0px;
  }
  
  .close-button {
    display: none;
    background-color: transparent;
    border: none;
    font-size: 44px;
    cursor: pointer;
    position: absolute;
    right: 43px;
    padding-top: 5%;
  }
  
  /* Mobile */
  @media screen and (max-width: 1200px) {
    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
    .navbar {
      padding: 8px 0;
    }
    .navbar.open {
      background-color: #11295a;
      height: 612px;
      max-width: 100%;
      padding: 5%;
    }
  
    .menu-icon {
      display: flex;
    }
  
    .nav-links a {
      color: #fff;
      text-decoration: none;
    }
  
    .navbar__logo.open {
      position: absolute;
      left: 43px;
      width: 10%;
      gap: 12px;
    }
  
    .nav-links {
      display: none;
      flex-direction: column;
      background-color: #11295a;
      position: absolute;
      top: 200px;
      left: 0;
      width: 100%;
      padding-bottom: 5%;
      gap: 32px;
      margin-top: -60px;
    }
  
    .nav-links li {
      font-size: 18px;
      font-weight: 500;
      line-height: 18.78px;
      text-align: center;
      color: #fff;
      transition: 0.3s;
    }
  
    .nav-links li:hover {
      color: #0d6efd;
    }
  
    .nav-links.open {
      display: flex;
    }
  
    .bar {
      background-color: #11295a;
      width: 48px;
      height: 3px;
      border-radius: 3px 0px 0px 0px;
      transition: transform 0.3s ease-in-out;
    }
  
    .bar-2 {
      width: 40px;
      height: 3px;
      border-radius: 3px 0px 0px 0px;
      background-color: #11295a;
    }
  
    .close-button {
      display: block;
      position: absolute;
      z-index: 100;
      color: white;
      width: 24px;
      height: 24px;
      gap: 0px;
      right: 43px;
      top: 0;
    }
  }
  
  @media (max-width: 600px) {
    .navbar.open {
      background-color: #11295a;
    }
  
    .menu-icon {
      display: flex;
    }
  
    .nav-links a {
      color: #fff;
      text-decoration: none;
    }
  
    .nav-links li {
      font-size: 16px;
      font-weight: 500;
      line-height: 18.78px;
      text-align: center;
      color: #fff;
      transition: 0.3s;
    }
  
    .nav-links li:hover {
      color: #0d6efd;
    }
  
    .nav-links.open {
      display: flex;
    }
  
    .bar {
      background-color: #11295a;
      width: 48px;
      height: 3px;
      border-radius: 3px 0px 0px 0px;
      transition: transform 0.3s ease-in-out;
    }
  
    .bar-2 {
      width: 40px;
      height: 3px;
      border-radius: 3px 0px 0px 0px;
      background-color: #11295a;
    }
  
    .close-button {
      position: absolute;
      display: block;
      z-index: 100;
      color: white;
      width: 24px;
      height: 24px;
      gap: 0px;
      right: 43px;
      top: 0;
    }
  }
  

  /* dropdown */
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  position: relative; 
  display: inline-block;
}

ul.dropdown {
  display: none; 
  position: absolute;
  top: 100%; 
  left: -50px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 10;
  list-style: none;
  padding-top: 10px;
  border-radius:10px
}

li:hover > ul.dropdown {
  display: block;  
}

ul.dropdown li {
  padding: 10px;
  width: 200px;
}

ul.dropdown li:hover {
  background-color: #f0f0f0;
}
