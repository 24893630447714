
.benefit{
    border-radius:20px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    padding: 40px 24px;
    margin-bottom: 32px;
    color:#11295A;
    background-color:white

}
.benefit h2 {
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 24px;
    color:#0D6EFD;

}
.benefit-cards{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 15px;
}
.benefit-card {
    width: 292px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    padding: 50px 24px;
    border-radius:20px;
    margin-bottom: 2%;
}
.benefit-card h4 {
    margin-top: 2%;
    width: 60%;
    font-size: 20px;
    font-weight: 700;
}

@media screen and (max-width:1028px) {
    .benefit-cards {
        justify-content: space-evenly;
    }
    
}

@media screen and (max-width:600px) {
.benefit h2 {

    text-align: center;
    font-size: 45px;
}
.benefit-cards {
    justify-content: center;
}
.bg-info-positions2 {
    display: none;
}
}
