.partners-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* margin: 0 0 56px; */
    position: relative;
    /* background-color:lightblue */
}


.partners-card {
    display: flex;
    flex-direction: column;
    /* justify-content: end; */
    /* overflow: hidden; */
    border-radius:20px;
    margin-top: 44px;

}
.partners-card img {
    width: 100%;
    height: 330px;
    /* height: 370px; */
    object-fit: contain;
    object-position: center ;
    /* background-color: red; */
}

.partners-card h4 {
    font-weight: 700;
    font-size: 20px;
    margin: 24px 0 16px;
}
.partners-card p {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
}
.partners-card a {
    margin: 24px 0 34px;
    display: inline-block;
}
.bg-partners {
    padding: 10%;
    top: 30%;
    right: 10%;
    z-index: -1;
}
@media screen and (max-width:600px) {
    .partners-cards {
        flex-direction: column;
        margin: 24px 0;
    }
    .partners-card {
        width: 100% !important;
    }
    .partners-card a {
        margin-bottom: 32px;
    }
    
}