.faq {
    padding: 6% 0;
    color: #11295a;
    position: relative;
  }
  
  .faq-title h3 {
    text-align: center;
  }
  
  .faq-title h5 {
    font-size: 18px;
    font-weight: 400;
    width: 62%;
    padding: 12px 64px;
    margin: 0 auto;
    text-align: center;
  }
  
  .faq-toggle {
    max-width: 90%;
    margin: 0 auto;
  }
  
  .faqs {
    /* padding: 2%  2%; */
    border-radius: 8px;
    border: 1px solid rgba(185, 224, 255, 1);
    margin: 2% 0px;
  }
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 32px 16px;
  }
  .faq-question button i {
    font-size: 22px;
  }
  
  .faqs h4 {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    line-height: 1.75rem;
  }
  
  .faq-answer {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    padding: 16px;
  }
  
  .faq-question button {
    background-color: transparent;
    border-color: transparent;
  }
  .bg-faq {
    top: 0;
    padding: 5%;
    top: 30%;
  }
  .m-circle-faq {
    padding: 5%;
    top: 25%;
    left: -3%;
    /* background-color:red */
  }
  .m-circle-faq .circle-inner {
    
  }
  
  @media screen and (max-width: 660px) {
    .faq-title h5 {
      width: 100%;
      padding: 12px 0 64px;
    }
  }
  @media screen and (max-width: 385px) {
    .faq-title h5 {
      font-size: 18px;
      line-height: 27px;
    }
    .faq-question {
      padding: 32px 16px;
    }
  }
  
  /* @media (max-width: 400px) {
  
      .faq-title h3 {
          font-family: Poppins;
          font-size: 3rem;
          font-weight: 700;
          line-height: 4.5rem;
          text-align: center;
      }
  
      .faq-title h3 span {
          font-family: Poppins;
          font-size: 3rem;
          font-weight: 700;
          line-height: 4.5rem;
          text-align: center;
      }
  
      .faq-title h5 {
          font-family: Raleway;
          font-size: 1.1rem;
          font-weight: 400;
          line-height: 1.65rem;
          letter-spacing: -0.25px;
          text-align: center;
      }
  
  
  
      .faq-toggle h4 {
          font-family: Raleway;
          font-size: 1.25rem;
          font-weight: 700;
          line-height: 1.75rem;
          text-align: left;
      }
  
      .faq-toggle h5 {
          font-family: Raleway;
          font-size: 1.1rem;
          font-weight: 400;
          line-height: 1.68rem;
          letter-spacing: -0.25px;
          text-align: left;
      }
  
      .faq-toggle h5 {}
  } */
  