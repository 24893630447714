  .industries-hero {
    border-radius: 40px;
    background: rgba(218, 230, 255, 0.4);
    text-align: center;
    padding: 7% 4%;
    position: relative;
    margin-bottom: 40px;
  
  }
  
  .industries-hero p {
    margin: 40px 0 48px;
  }
  
  /* industries-cards */
  .industries-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
  }
  
  .industries-card {
    width: calc(50% - 1rem);
  
    height: auto;
    border-radius: 20px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.04);
    /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.12); */
  
    padding: 65px 25px;
    margin-top: 2rem;
    z-index: 10;
  }
  
  .industries-card h3 {
    font-size: 48px;
    font-weight: 700;
    line-height: 4.5rem;
  }
  
  .industries-card h4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin: 24px 0;
  }
  .industries-card p {
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 27px;
    letter-spacing: 0.25px;
  }
  .bg-industries {
    padding: 3%;
    top: 35%;
    right: 25%;
  }
  .bg-industries-cards {
    padding: 10%;
    top: 40%;
  }
  .l-circle-industries {
    /* padding: 128px; */
    padding: 10%;
    top: 25%;
    right: 3%;
  }
  .xs-circle-industries {
  /* padding: 67px; */
  padding: 5%;
  top: 50%;
  right: 2%;
  }
  .s-circle-industries {
    padding: 5%;
    bottom: 0;
    left: 1%;
  }
  /* end */
  
  @media screen and (max-width: 800px) {
    .industries-hero {
      margin-bottom: 24px;
    }
    .industries-cards {
      flex-direction: column;
    }
    .industries-card {
      width: 100%;
    }
    .bg-industries {
      display: none;
    }
    .bg-industries-cards {
      display: none;
    }
    
  }
  @media screen and (max-width: 600px) {
    .industries-card {
      padding: 24px 29px;
    }
  }
  