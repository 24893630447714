.services {
    padding: 69px 0 226px;
    position: relative;
}
.services-info {
    margin: 40px 0;
}
.bg-long-services{
    padding: 10%;
    bottom: 25%;
    right: 10%;
}
.bg-small-services {
    bottom:10%;
    left: 10%;
}
/* it-outsourcing */
.it-outsourcing{
    padding: 56px 0;
    position: relative;
}
.it-outsourcing-info {
   margin: 32px 0 35px ;
}
.bg-outsource {
    padding: 5%;
    top: 50%;
    left: 7%;

}
.l-circle-services {
    top: 10%;
    padding: 13%;
    right: 5%;
}
.s-circle-services {
    top: 37%;
    padding: 7%;
    right: 20%
}
.xs-circle-services {
    bottom: 10%;
    left: 1%;
    padding: 5%;
}
@media screen and (max-width:765px) {
    .services {
        padding-bottom: 110px;
    }
    .bg-long-services {
        top: 5%;
        left: 5%;
        background: rgb(74 134 255 / 10%);
        box-shadow: 0px 0px 100px 135px rgb(74 134 255 / 10%);
    }
    .bg-outsource {
        bottom: 10%;
    }
    
}