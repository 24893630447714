.savings-grid {
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-content: center;
    padding: 20px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .savings-grid-2{
    display: flex;
    gap: 20px;
    margin: 0 auto;
    /* background-color:green ; */
    justify-content: center; /* Центрирование по горизонтали */
    align-items: center; 

  }
  .savings-grid-2 >.savings-card{
    height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .savings-grid-2>.savings-card.css-66gjpw-MuiResponsiveChart-container>svg{
    /* width: 10% !important;
    margin-left: 60px;
    padding-left: 60px; */
    /* background-color:yellow */
  }
  .css-1evyvmv-MuiChartsSurface-root{
    width: 100% !important;
  }
  .savings-main-block{
    display: flex;
    justify-content: center;
    height: auto;
    border-radius: 20px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.04);  
    padding: 65px 25px;
    margin-top: 2rem;
    height: auto; 
    width: 80%; 
    margin: 10px auto;

  }
  .savings-item {
    text-align: center;
    width: 30%;
    
  }
  .savings-card{  
    height: auto;
    border-radius: 20px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.04);  
    padding: 65px 25px;
    margin-top: 2rem;
    z-index: 10;
  }
  .savings-card-long {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.04); 
    margin-top: 4%;
    padding: 2%;
    border-radius: 20px;
    /* margin-top: 2rem; */

  }
  .savings-item-long{
    width: 40%;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */

    border-radius: 20px;


    
  }

 /* .medium{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
  } */

.savings-item h2 {
    margin-top: 10px;
  }
  .savings-item.large {
    width: 100%; 
  }
  .savings-grid > .savings-item:nth-child(3),
.savings-grid > .savings-item:nth-child(4),
.savings-grid > .savings-item:nth-child(5) {
  grid-column: span 1; /* Вторая строка состоит из 3 элементов */
}
.savings-title{
  font-size: 20px;
  margin-bottom: 17px;
}
.savings-title-bold{
  font-weight: bold;
}
@media screen and (max-width:950px) {
  .savings-item-long{
    background-color:pink;
    /* width: 50%; */
    /* width: 40%; */
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
  .savings-main-block{
    padding: 45px 15px;
  }
  .savings-item-long .MuiCharts-PieChart-root {
    width: 100% !important;
    height: 100% !important;
  }
  
}
@media screen and (max-width:843px) {
.savings-grid{
  flex-direction: column;
  align-items:center;
}
.savings-card{
  width: 60%;
}
.savings-main-block{
  /* display: block; */
  flex-direction: column;
  justify-content: center;
  width: 60%;
}
.savings-item-long {
  width: 80%;
  /* background: rgba(255, 255, 255, 1);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.04); 
  margin-top: 4%;
  padding: 2%;
  border-radius: 20px; */
}
  
}
@media (max-width: 768px) {
  /* .savings-item-long .MuiCharts-PieChart-root {
    width: 150px !important;
    height: 150px !important;
  } */
}


@media screen and (max-width:600px) {
  .savings-grid{
    display: block;
  }
  .savings-item{
    width: 100%;
  }
  
}
.css-66gjpw-MuiResponsiveChart-container{
  width: 100% !important;
  /* background-color:pink */
}
.css-66gjpw-MuiResponsiveChart-container>svg{
  width: 100% !important ;
  margin-left: 90px;
}
@media screen and (max-width:1070px) {
  .css-66gjpw-MuiResponsiveChart-container>svg{
    margin-left: 50px;
  }
  .savings-grid-2{
    flex-direction: column;
    /* background-color:green ; */
    justify-content: center; /* Центрирование по горизонтали */
    align-items: center; 

  }
  
}
@media screen and (max-width:500px) {
  .css-1evyvmv-MuiChartsSurface-root{
    margin-left: 80px;
  }

  
}
@media screen and (max-width:430px) {
  .css-1evyvmv-MuiChartsSurface-root{
    margin-left: 80px;
  }
}