.hero-careers {
    background-image: url("../assets/mainBgs/careersBg.png");
    background-position: center;
    background-size: cover;
    color: white;
    border-radius: 20px;
    margin-bottom: 48px;
    text-align: center;
    padding: 85px 20%;
    /* height: 658px; */
  
  }
  .hero-careers p {
    margin: 40px 0;
  }
  @media screen and (max-width:400px) {
    .hero-careers {
      padding: 80px 1%;
    }
    
  }
  