.position-form {
    margin: 6%;
    background-color: #eef8ff;
    padding: 64px;
    border-radius: 20px;
  }
  .position-inputs {
    width: 60%;
  }
  .position-input-blocks {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    margin-bottom: 4%;
  }
  .position-input-block {
    width: 48%;
  }
  
  .part {
    display: block;
  }
  
  .full-inputs {
    width: 100%;
    margin-top: 8px;
    padding: 8px 16px;
    border-color: transparent;
    border-radius: 4px;
    border: 1px solid #f2f4f7;
    background-color: white;
  }
  .full-inputs::placeholder {
    color: #11295a;
    font-size: 14px;
    font-family: Raleway;
    font-weight: 400;
  }
  .redLabels {
    display: block;
  }
  .redLabels::after {
    content: "*";
    color: red;
    margin-left: 2px;
  }
  .radio-inputs {
    margin: 4% 0;
    /* background-color:red; */
    display: flex;
  }
  .radio-inp {
    border-color: #0c5ae9;
    background-color: transparent;
  }
  .radio-label {
    margin: 0 24px;
  }
  
  .inp-btn {
    /* margin: 4% 0; */
    background-color: #2a91ff;
    color: white;
    padding: 16px 0;
    border-color: transparent;
    font-family: Raleway;
    width: 100%;
  }
  .position-inputs p {
    margin: 40px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
  .full-inputs.input-error {
    border: 1px solid red;
  }
  
  .error-text {
    color: red;
    font-size: 0.8em;
    margin-top: 5px;
  }
  
  @media screen and (max-width: 890px) {
    .position-inputs {
      width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    .position-form {
      padding: 32px 16px;
    }
    .position-input-blocks {
      display: block;
    }
    .position-input-block {
      width: 100%;
      margin-bottom: 32px;
    }
    .position-input-block label {
      margin-bottom: 8px;
    }
    #linkToLnkdn label {
      margin-bottom: 8px;
    }
  }
  