.info-positions-cards {
    margin: 56px 0 32px;
    position: relative;
  }
  .info-positions-card {
    border-radius: 20px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    padding: 40px 24px;
    margin-bottom: 32px;
    color: #11295a;
    background-color:white
  }
  .info-positions-card h2 {
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 24px;
    color: #0d6efd;
  }
  .info-positions-card p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }
  .info-positions-card ul li {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    margin-left: 20px;
  }
  .bg-info-positions {
    padding: 10%;
  z-index: -1;
  }
  .bg-info-positions2 {
    padding: 10%;
  z-index: -1;
  bottom: 0;
  right: 0;
  }
  
  @media screen and (max-width: 400px) {
    .info-positions-cards {
      margin: 24px 0 32px;
    }
    .info-positions-card h2 {
      font-size: 45px;
    }
  }
  