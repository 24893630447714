footer {
    background-color: #11295a;
    color: #eef8ff;
    padding: 10% 0 8%;
    margin-top: 160px;
  }
  .footerNav {
    display: flex;
    /* width: 60%; */
    justify-content: flex-end;
  }
  .footerBlocks {
    display: flex;
    justify-content: space-between;
  }
  .titleWork {
    font-weight: 200;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .copyright {
    font-size: 14px;
    font-weight: 400;
    font-weight: 200;
  }
  .logoLinks {
    margin: 5% 0;
  }
  .logoLinks img {
    display: inline-block;
    margin-right: 2%;
  }
  /* .input-bottom-line{
    border-bottom: 1px solid #eef8ff;
    margin-top: 10px;

  } */
  /* .footer-inp{
    width: 50%;
  } */
  /* .footerContact {
    width: 50%;
    margin-top: 5%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5%;
  } */
  
  /* .footerInput {
    border-color: transparent;
    padding: 1% 0 5%;
    background-color: transparent;
    color:#EEF8FF;
    outline: none;
  } */
  /* .footerInput::placeholder {
    font-size: 14px;
    font-weight: 200 !important;
    color:#EEF8FF;
  
  } */
  /* .footerBtn {
    padding: 4.5px 27px;
    border-radius: 500px;
    border-color: transparent;
  }
  .footerBtn i {
    font-size: 24px;
  } */
  .footerNavList {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items:end
  }
  .footerNavList li {
    list-style: none;
    font-size: 14px;
    margin-bottom: 20px;
    color: white;
  }
  .footerNavList li a {
    text-decoration: none;
  }
  .footerNavList a {
    color: white;
    font-weight: 300;
    font-size: 18px;
  }
  .footerNavList h2 {
    font-size: 22px;
    font-size: 700;
    margin-bottom: 10%;
    font-family: Raleway;
  }
  
  /* .modal-overlay {
    position: fixed;
    top: 4%;
    right: 1%;
    padding: 2% 5%;
    z-index: 1000;
    border-radius: 12px;
    background-color: white;
    box-shadow: 6px 0.25rem 31px 0 rgba(0, 0, 0, 0.5);
    color: #11295a;
    font-size: 20px;
    font-family: Raleway;
    font-weight: 500;
    text-align: center;
  } */
  /* .footerBtn {
    background-color: #d8eeff;
    color: #11295a;
    margin-bottom: 5px;
  } */
  .error {
    color:red;
    margin-top: 5px;
  }
  @media screen and (max-width: 770px) {
    /* .footerBlocks {
      flex-direction: column;
      align-items: center;
      background-color:yellow
    } */
    /* .footerContact {
      width: 100%;
      margin: 40px 0 64px;
    } */
    /* .footerLogos {
      margin-bottom: 4%;
      display: flex;
      flex-direction: column;
      align-items: center;
    } */
    .footerNav {
      /* flex-direction: column;
      align-items: center; */
      /* background-color:green; */
    }
    .footerNavList h2 {
      /* margin: 40px 0 24px; */
    }
    /* .footerNavList li {
      width: 100%;
    }
    .logoLinks {
      width: 100%;
    } */
    .footerNavList li a {
     font-size: 12px;
     margin-bottom: 0;
    }
    .copyright {
      text-align: center;
    }
  }
  @media screen and (max-width: 455px) {
    footer {
      margin-top: 45px;
    }
    .footerLogos {
      width: 100%;
    }
  }
  