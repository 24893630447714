.review {
    margin: 0 auto;
    padding: 56px 64px;
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .star {
    width: 116px;
    height: 18.89px;
  }
  
  .review-avatar {
    width: 56px;
    height: 56px;
  }
  
  .review h3 {
    font-weight: 400;
    font-size: 24px;
    margin: 40px 0;
  }
  .review-user {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .review-user-block {
    border-right: 1px solid #000;
    padding-right: 10px;
  }
  
  .review-user-info h4 {
    font-size: 20px;
    font-weight: 700;
  }
  
  .review-user-info h5 {
    font-size: 18px;
    font-weight: 400;
    margin-top: 5px;
  }
  
  .review-user-company img {
    width: 120px;
    height: 48px;
  }
  .slider-buttons-review {
    margin-top: 40px;
  }
  
  @media screen and (max-width: 600px) {
    .review {
      margin: 140px 0;
      width: 100%;
      padding: 0;
    }
    .review-user {
      flex-direction: column;
    }
    .review-user-block {
      border-right: none;
      border-bottom: 1px solid black;
      padding-right: 0;
      padding-bottom: 10px;
    }
  }
  