.main-positions {
    background-image: url("../assets/mainBgs/Careers.jpg");
    background-size: cover;
    border-radius: 40px;
    padding: 45px 0 65px 45px;
    margin-top: 65px;
    background-position: center;
  }
  .positions-btns button {
    color: #11295a;
    background-color: white;
    border-radius: 8px;
    padding: 8px 24px;
    border-color: transparent;
    margin-right: 2%;
    font-size: 20px;
    font-weight: 700;
  }
  .main-positions b {
    padding: 8px 32px;
    font-size: 32px;
    font-weight: 400;
    background-color: #0c5ae9;
    color: white;
    border-radius: 32px;
    border-color: transparent;
    letter-spacing: 0.4px;
  }
  .main-positions h1 {
    color: white;
    margin: 32px 0;
  }
  .main-positions p {
    width: 52%;
    color: white;
  }
  .main-positions button {
    margin-top: 32px;
  }
  @media screen and (max-width: 614px) {
    .main-positions {
      margin-top: 24px;
      padding: 40px 16px;
    }
    .main-positions b {
      padding: 6px;
      width: 100%;
      display: inline-block;
      text-align: center;
      font-size: 24px;
    }
    .main-positions p {
      width: 100%;
    }
  }
  /* .careerTitle{
          background: #0C5AE9;
          color:white;
          padding: 8px 32px;
          border-color: transparent;
          border-radius:65px;
          font-size: 32px;
          font-weight: 400;
          font-family: Raleway;
      }
      .careerTypes{
          margin-top: 6%;
  
          button {
              color:#11295A;
              background-color: white;
              border-radius:8px;
              padding: 8px 24px;
              border-color:transparent;
              margin-right: 2%;
              font-size: 20px;
              font-weight: 700;
          }
      }
      h1 {
          font-weight: 700;
          font-size: 88px;
          font-family: Poppins;
          color:white
      }
      p{
          font-weight: 400;
          font-size: 24px;
          color:white;
          width: 50%;
          font-family: Raleway;
          margin-bottom: 3%;
      } */
  
  /* @media screen and (max-width:600px) {
      .mainCareers{
  
          .careerTypes{
              button {
          
                  margin-bottom: 2%;
              }
          }
          h1 {
              font-size: 50px;
          }
          p {
              width: 90%;
              font-size: 20px;
          }
              
          }
      } */
  