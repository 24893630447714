.make-your-banner {
    width: 100%;
    background: #11295a;
    padding: 4% 0;
  }
  
  .make-your-banner-text {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 7.894rem;
    font-weight: 700;
    text-align: center;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .macbook {
    max-width: 33%;
    margin: 0 15px ;
  }
  .notebook {
    max-width: 25%;
    margin: 0 15px ;
  
  }
  @media screen and (max-width: 660px) {
    .make-your-banner-text {
      font-size: 5rem;
    }
  }
  @media screen and (max-width: 450px) {
    .make-your-banner-text {
      font-size: 4rem;
    }
  }
  
  @media screen and (max-width: 385px) {
    .make-your-banner-text {
      font-size: 48px;
      font-weight: 700;
    }
    .macbook {
      max-width: 90%;
    }
    .notebook {
      max-width: 30%;
    }
  }
  