.it-outstaffing {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 56px 0;
  }
  .it-outstaffing p {
    margin: 30px 0 35px;
  }
  
  .itoutstaffing-blocks-one {
    width: 44.58%;
  }
  
  .it-outstaffing-staff {
    /* width: 22.1%; */
    width: 304px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
    border-radius: 1.25rem;
    padding: 24px 29px;
  }
  .end {
    margin-top: 10%;
  }
  .center {
    margin-top: 5%;
  }
  
  .it-outstaffing-staff h4 {
    margin: 24px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
  
  .it-outstaffing-staff img {
    width: 70px;
    height: 70px;
  }
  
  .it-outstaffing-staff p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.25px;
    color: #11295a;
  }
  @media screen and (max-width: 984px) {
    .it-outstaffing {
      flex-direction: column;
    }
    .it-outstaffing-staff {
      width: 100%;
    }
    .itoutstaffing-blocks-one {
      width: 100%;
    }
    .end {
      margin-top: 5%;
    }
  }
  