.team {
    padding: 6% 0;
  }
  
  .team-block {
    text-align: center;
    gap: 1.5rem;
  }
  
  .team-title {
    width: 90%;
    margin: 0 auto;
    gap: 1.5rem;
  }
  
  .team p {
    color: #144194;
    margin: 1.67% 0 4.65%;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.875rem;
    width: 63.54%;
    margin: 0 auto;
    padding: 2.5rem 0px;
  }
  
  .cards-team {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 3% 0;
  }
  
  .image-container {
    position: relative;
    display: inline-block;
    width: 23%;
    height: 490px;
    overflow: hidden;
    border-radius: 15px;
  
  }
  .image-container img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    /* filter: grayscale(100%); */
    transition: filter 0.3s ease;
  
  }
  
  .image-text {
    position: absolute;
    bottom:  0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: inherit;
    opacity: 0;
    transition: opacity 0.3s;
    width: 100%;
    padding: 24px 0 21px 24px;
  }
  /* .image-container img:hover{
    filter: none;
  } */
  .image-container:hover img,
  .image-container:hover .image-text {
    opacity: 1;
    filter: none;
  }
  
  
  .image-text h6 {
    font-size: 18px;
    font-weight: 300;
    color: rgb(174, 174, 174);
  }
  
  .image-text h3 {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 1.5rem 0;
  }
  @media screen and (max-width: 680px) {
  
    .cards-team {
      flex-direction: column;
      align-items: center;
    }
    .card-team {
      margin-bottom: 3%;
    }
    .image-container{
      width: 80%;
    }
  }
  
  @media screen and (max-width: 385px) {
    .team-title {
      width: 100%;
    }
    .team p {
      font-size: 18px;
      width: 100%;
    }
    .image-container  {
      width: 100%;
    }
  
  }
  