a {
  text-decoration: none;
  color: white;
}
.main-title {
  font-weight: 700;
  font-size: 5.3em;
}
.main-title-second {
  font-weight: 700;
  font-size: 4.4em;
}
.main-title-info {
  font-size: 2em;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0.4px;
}
.main-text-h3 {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}
.main-btn {
  background-color: #2a91ff;
  color: white;
  padding: 16px 15%;
  border-color: transparent;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  line-height: 18.78px;
}
.dark-blue {
  color: #11295a;
}

.middle-blue {
  color: #0c5ae9;
}
.light-blue {
  color: #2a91ff;
}
.raleway {
  font-family: Raleway;
}
.poppins {
  font-family: "Poppins", sans-serif;
}

.bg-long {
  position: absolute;
  background: rgb(74 134 255 / 30%);
  box-shadow: 0px 0px 100px 135px rgb(74 134 255 / 30%);
  border-radius: 50%;
  /* background-color:red */
}
.bg-medium {
  position: absolute;
  background: rgb(74 134 255 / 22%);
  box-shadow: 0px 0px 100px 135px rgb(74 134 255 / 22%);
  border-radius: 50%;
}
.bg-small {
  position: absolute;
  background: rgb(74 134 255 / 15%);
  box-shadow: 0px 0px 100px 135px rgb(74 134 255 / 15%);
  border-radius: 50%;
}

/* good-version */
/* .xl-circle {
  position: absolute;
  padding: 12%;
  top: 20%;
  right: 10%;
  border-radius: 50%;
  background-color: #fbfffe;
  box-shadow: inset 6px 4px 90px 0 rgba(0, 0, 0, 0.12);
  opacity: 0.5;
} */

.xl-circle {
  position: absolute;
  top: 20%;
  right: 0%;
  padding: 12%;
  border-radius: 50%;
  background-color: rgba(251, 255, 254, 0.5);
  box-shadow: inset 6px 4px 90px 0 rgba(0, 0, 0, 0.12);
  opacity: 0.5;
  overflow: hidden; /* Ensure inner content stays within bounds */
}

.xl-circle-inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
  filter: blur(10px); /* Adjust the blur amount as needed */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0; /* Ensure it stays behind the main content */
  transform: scale(0.9); /* Scale down to leave space for the border */
}
.circle-inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
  filter: blur(10px); /* Adjust the blur amount as needed */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0; /* Ensure it stays behind the main content */
  transform: scale(0.9); /* Scale down to leave space for the border */
}
.circle {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(251, 255, 254, 0.5);
  box-shadow: inset 6px 4px 90px 0 rgba(0, 0, 0, 0.12);
  opacity: 0.5;
  overflow: hidden; /* Ensure inner content stays within bounds */
}
.l-circle {
  position: absolute;
  padding: 8%;
  bottom: 2%;
  left: 2%;
  border-radius: 50%;
  background-color: #fbfffe;
  box-shadow: inset 6px 4px 90px 0 rgba(0, 0, 0, 0.12);
  opacity: 0.5;
}
.m-circle{
  position: absolute;
  padding: 5%;
  top: 2%;
  right: 2%;
  border-radius: 50%;
  background-color: #fbfffe;
  box-shadow: inset 6px 4px 90px 0 rgba(0, 0, 0, 0.12);
  opacity: 0.5;
}
@media screen and (max-width:867px) {
  .main-title {
    font-size: 4.2em;
  }
  
}

@media screen and (max-width: 680px) {
  .main-title {
    font-size: 4em;
  }
  .main-title-info {
    font-size: 1.5;
  }
  .main-title-second {
    font-size: 3.5em;
  }
  .main-text-h3 {
    font-size: 18px;
    line-height: 27px;
  }
}
@media screen and (max-width:627px) {
  .main-title {
    font-size: 2.8em;
  }
  
}
@media screen and (max-width:435px) {
  .main-title {
    font-size: 2.5em;
  }
  
}
@media screen and (max-width: 416px) {
  .main-title {
    /* font-size: 3.5em; */
  }
  .main-title-info {
    font-size: 18px;
    line-height: 27px;
  }
  .main-title-second {
    font-size: 48px;
  }
  .main-btn {
    width: 90%;
  }
}

@media screen and (max-width:390px) {
  .main-title {
    font-size: 2.3em;
  }
  
}
