.hero-partners {
    background-image: url('../assets/mainBgs/partnersBg.svg');
    padding: 80px 48px ;
    color:white;
    background-size:cover;
    border-radius: 40px;
    background-size:cover;
    background-position:center;
}
.hero-partners p {
    margin: 40px 0;
}
@media screen and (max-width:507px) {
    .hero-partners{
        text-align: center;
        padding: 95px 0;
        margin: 24px 0;
    }
    
}
