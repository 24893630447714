.employees{
    position: relative;
    margin-bottom: 40px;
  }
  .employees-block h3 {
    margin: 56px 0 48px;
  }
  .employees-btns {
  }
  .employees-btns button {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    background-color: transparent;
    border: none;
    /* display: inline-block; */
    margin-right: 40px;
    border: 2px solid transparent;
  }
  .employees-btns button:hover {
    border-bottom: 2px solid #0c5ae9;
  }
  .employees-btns button:focus {
    border-bottom: 2px solid #0c5ae9;
  }
  
  .employees-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(304px, 1fr));
    gap: 16px;
    margin-bottom: 56px;
    grid-auto-flow: dense;
  }
  .employees-user {
    width: 100%;
    border-radius: 20px;
    box-shadow: 6px 4px 31px 0 rgba(0, 0, 0, 0.1);
    margin-top: 48px;
  background-color:white;
    overflow: hidden;
    /* height: 230px; */
  }
  .user-img {
    width: 100%;
    height: 230px;
    object-fit: cover;
    object-position: end;
  }
  .employees-lnkdn {
    margin: 16px 0 24px;
  }
  .employees-info h4 {
    font-weight: 700;
    font-size: 20px;
  }
  
  .employees-info {
    padding: 16px;
  }
  .employees-lnkdn h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }
  .employees-info h6 {
    font-weight: 400;
    font-size: 14px;
    margin: 4px 0 8px;
  }
  .employees-info p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 24px;
  }
  .employees-info button {
    width: 100%;
  }
  .bg-small {
    padding: 10%;
    /* background-color:#0c5ae9; */
    top: 50%;
    z-index: -1;
  
  }
  .m-circle-employees {
    padding: 6%;
    bottom:17%;
    left: -2%;
    z-index: -1;
  }
  .s-circle-employees1{
    padding: 3%;
    bottom:-5%;
    right: 0;
  }
  .s-circle-employees2 {
    padding: 3%;
    bottom: -8%;
    right: 3%;
  }
  @media screen and (max-width: 1056px) {
    .employees-cards {
      justify-content: space-evenly;
    }
    .employees-btns {
      /* text-align: center; */
      display: flex;
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width:450px) {
    .employees-block h3 {
      margin: 24px 0 40px;
    }
    .employees-btns {
      justify-content: center;
  
    }
    
  }
  