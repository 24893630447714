.info-block {
    background-color: #2a91ff;
    color: white;
    width: 20%;
    padding: 16px;
    border-radius: 20px;
    margin-left: 2%;
  }
  .info-title {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 65px;
  }
  .info-block button {
    background-color: transparent;
    border-color: transparent;
    font-size: 18px;
    color: white;
    font-weight: 300;
  }

  @media screen and (max-width: 1000px) {
    .info-block {
      width: 48%;
      margin-left: 0;
    }
  }
  @media screen and (max-width: 385px) {
    .info-block {
      width: 100%;
      margin-bottom: 3%;
    }
  }
  