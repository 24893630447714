.expertise {
    /* margin: 20% 8%; */
    padding: 40px 0 32px;
    position: relative;
  }
  .services-blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .service-block {
    border-radius: 1.25rem;
    /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.04); */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  
    padding: 65px 25px;
    margin-top: 32px;
    background-color: rgba(255, 255, 255, 1);
  }
  .service-block-title {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 24px 0;
  }
  .service-block p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.68rem;
  }
  .bg-expertise {
    padding: 10%;
    top: 15%;
    right: 10%;
    z-index: -1;
    /* background-color:red */
  }
  @media screen and (max-width: 660px) {
    .services-blocks {
      flex-direction: column;
    }
    .service-block {
      width: 100% !important;
      padding: 32px 29px;
    }
  }
  