.partners {
    background: linear-gradient(rgba(91, 143, 218, 0), rgba(91, 143, 218, 0.65), rgba(91, 143, 218, 0)); 
    padding: 10% 0px;
    text-align: center;
}

.company-title {
    width: 20%;
}

.partners h2 {
    font-size: 32px;
    font-weight: 400;
    /* line-height: 48px; */
    letter-spacing: 0.4000000059604645px;
    text-align: center;
    padding: 48px 0;
}

.partner-user img {
    width: 56px;
    height: 56px;
}

.partner-user h4 {
    margin: 16px 0 5px ;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}


.partner-user h5 {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 48px;
}

.slide-button-square {
    width: 59px;
    height: 10px;
    gap: 0px;
    border-radius: 5px;
    background: #0C5AE9;
    margin-right: 10px;
    border-color: transparent;
}

.slide-button-circle {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-left: 10px;
    border-color:transparent;
    background-color:#89CFFF;
}
.slide-button-circle.active {
    background-color:#0C5AE9
}

@media screen and (max-width:660px) {
    .partners h2 {
        font-size: 18px;
        padding: 18px 0;

    }
    
}
@media screen and (max-width:385px) {
    .company-title {
        width: 50%;
    }

    
}
