
  .footerLogos {
    border-radius:12px;
    padding:40px 20px;
    width: 50%;
  }
  .titleWork {
    font-weight: 200;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .logoLinks {
    margin: 5% 0;
  }
  .logoLinks img {
    display: inline-block;
    margin-right: 2%;
  }
  .input-bottom-line{
    border-bottom: 1px solid #11295a;
    margin-top: 10px;
    width: 100%;

  }
  .footerContact {
    margin-top: 5%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5%;
  }
  
  .footerInput {
    border-color: transparent;
    padding: 2% 0 ;
    background-color: transparent;
    color: #11295a;
    outline: none;
  }
  .footerInput::placeholder {
    font-size: 18px;
    font-weight: 200 !important;
    color:#11295a;
  
  }
  .getTouchBtn {
    padding: 4.5px 27px;
    border-radius: 500px;
    border-color: transparent;
    margin-top: 30px;
    display: inline-block;
    font-size: 20px;
    background-color: #d8eeff;
    color: #11295a;
  }
  
  .modal-overlay {
    position: fixed;
    top: 4%;
    right: 1%;
    padding: 2% 5%;
    z-index: 1000; 
    border-radius: 12px;
    background-color: white;
    box-shadow: 6px 0.25rem 31px 0 rgba(0, 0, 0, 0.5);
    color: #11295a;
    font-size: 20px;
    font-family: Raleway;
    font-weight: 500;
    text-align: center;
  }

  .spinner {
    width: 20px;
    height: 20px;
    border: 3px solid rgba(0, 0, 0, 0.2);
    border-top-color: #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-left: 10px;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .footerBtn {
    background-color: #d8eeff;
    color: #11295a;
    margin-bottom: 5px;
  }
  .error {
    color:red;
    margin-top: 5px;
  }

   @media screen and (max-width:1000px) {
    .footerContact{
      width: 100%;
    }
    .footerLogos{
      width: 100%;
    }
    
   }
  @media screen and (max-width: 770px) {
    .footerContact {
      width: 100%;
      margin: 40px 0 64px;
    }
    .formGetInTouch {
      width: 100%;
    }
  }
  @media screen and (max-width: 455px) {
    footer {
      margin-top: 45px;
    }
    .footerLogos {
      width: 100%;
    }
  }
  