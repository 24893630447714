.slider {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
  text-align: center;
  padding: 59px 0;
}

.slider-track {
  display: flex;
  animation: scroll 15s linear infinite;
}

.slideNew {
  flex: 0 0 auto;
  margin: 0 20px;
  width: 200px;
}

.slideNew img {
  max-width: 80%;
  width: 245.96px;
  height: 52.84px;
  opacity: 1;
}

@media screen and (max-width: 385px) {
  .slideNew img {
      width: 145.96px;
      height: 32.84px;
  }
}

@keyframes scroll {
  from {
      transform: translateX(0);
  }
  to {
      transform: translateX(-50%);
  }
}
