.careers-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
  }
  .big {
    width: 65%;
  }
  .small {
    width: 32%;
  }
  .career-card {
    border-radius: 20px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.04);
    padding: 65px 29px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* align-items:center; */
  }
  .career-card-description {
    height: 50px;
  }
  .career-card h3 {
    font-weight: 700;
    font-size: 48px;
  }
  .career-card h4 {
    font-weight: 700;
    font-size: 20px;
  }
  
  .career-card p {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
  }
  .bg-careers-cards {
    padding: 10%;
    top: 0;
    z-index: -1;
  }
  .career-card-main-title{
    display: flex;
    gap: 5px;
    align-items:center;
    margin-bottom: 24px;
  }
  .career-card-main-title h3 {
    display: flex;
  }
  /* @media screen and (max-width:1405px) {
    .career-card-description{
      height: 100%;
    }
    
  } */
  @media screen and (max-width:1135px) {
    .career-card-description{
      height: 100px;
    }
    
  }
  @media screen and (max-width:980px) {
    .big {
      width: 49%;
    }
    .small{
      width: 49%;
    }
    
  }
  @media screen and (max-width:925px) {
    .career-card-description{
      height: 130px;
    }
    
  }
  @media screen and (max-width: 786px) {
    .careers-cards {
      flex-direction: column;
    }
    .big {
      width: 100%;
    }
    .small {
      width: 100%;
    }
    .career-card-description{
      height: 100%;
    }
  }
  
  @media screen and (max-width: 375px) {
    .careers-cards {
      margin-top: 81px;
    }
    .career-card {
      padding: 24px 29px;
    }
  }
  