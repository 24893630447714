.hero-calendar {
    padding: 68px 0 4px;
    position: relative
  }
  .hero-calendar-btns {
    display: flex;
    justify-content: flex-end;
  }
  .hero-calendar p {
    margin: 40px 0;
    width: 48%;
  
  }
  .calendar-btn {
    margin-bottom: 31px;
  }
  
  .year-calendar {
    padding: 64px;
    border-radius: 40px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.09), 0 0 4px 0 rgba(0, 0, 0, 0.09);
    margin: 52px 0 72px;
    background-color:white;
    position: relative;
  }
  .year {
    font-size: 48px;
    font-weight: 700;
    line-height: 72px;
  }
  
  .calendar-office {
    background: rgba(255, 255, 255, 1);
    border-radius: 20px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.04);
    margin: 64px 0px;
    padding: 40px 24px;
    position: relative;
  }
  
  .calendar-office h4 {
    font-size: 48px;
    font-weight: 700;
    line-height: 72px;
    color: rgba(12, 90, 233, 1);
  }
  
  .calendar-office p {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    width: 100%;
  }
  .service-development {
    border-radius: 64px;
    background-color: #eef8ff;
    padding: 5%;
    margin-bottom: 5%;
  }
  .service-development h4 {
    font-family: Poppins;
    font-weight: 700;
    font-size: 48px;
    color: #11295a;
  }
  .service-development p {
    font-weight: 400;
    font-size: 18px;
    font-family: Raleway;
    color: #11295a;
    margin: 1.5% 0 4% 0;
  }
  .bg-calendar{
    padding: 10%;
    top: 9%;
    right: 10%;
    z-index: -1;
  }
  .bg-year-calendar{
    padding: 10%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .l-circle-calendar {
    padding: 12%;
    top: 2%;
    right: 7%;
  }
  .m-circle-calendar {
    padding: 7%;
    top: 8%;
    right: 18%;
  }
  .s-circle-calendar {
    padding: 5%;
    top: 17%;
    left: -4%;
  }
  .xs-calendar-office1{
    padding: 3%;
    bottom: -8%;
    right: -3%;
    z-index: -1;
  }
  .xs-calendar-office2{
    padding: 3%;
    bottom: -15%;
    right: 0;
    z-index: -1;
  }
  @media screen and (max-width: 680px) {
    .hero-calendar-btns {
      justify-content: space-between;
    }
    .hero-calendar p {
      width: 100%;
    }
    .year-calendar {
      padding: 32px 16px;
    }
  }
  
  @media screen and (max-width: 385px) {
    .hero-calendar{
      padding: 16px 4px;
    }
    .hero-calendar-btns {
      display: block;
    }
    .bg-calendar {
      display: none;
    }
  }
  