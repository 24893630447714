.hero {
    padding: 5% 0 ;
    position: relative;
  }
  .hero h1 {
    width: 80%;
  }
  .hero p {
    width: 43%;
    padding: 4% 0 0;
  }
  .hero-btns {
    display: flex;
    justify-content: flex-end;
  }
  .hero-bg-long {
    bottom:35%;
    right: 15%;
    padding: 10%;
  }
  @media screen and (max-width:1000px) {
    .hero-btns {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
    
  }
  @media screen and (max-width: 680px) {
    .hero h1 {
      width: 100%;
    }
    .hero p {
      width: 100%;
    }
    .hero-btns {
      justify-content: space-between;
    }
  }
  @media screen and (max-width: 385px) {
    .hero-btns {
      display: block;
    }
    .hero-bg-long {
      top: 0;
      bottom: 0;
      background: rgb(74 134 255 / 20%);
    box-shadow: 0px 0px 100px 135px rgb(74 134 255 / 20%);
    }
  }
  