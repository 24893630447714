.mission {
    padding: 15% 0;
    text-align: center;
    background: linear-gradient(
      rgba(91, 143, 218, 0),
      rgba(91, 143, 218, 0.65),
      rgba(91, 143, 218, 0)
    );
  }
  
  .mission-info b {
    padding: 12px 24px;
    font-size: 24px;
    font-weight: 500;
    background-color: #0c5ae9;
    color: white;
    border-radius: 32px;
    border-color: transparent;
    letter-spacing: 4px;
  }
  .mission-info p {
    font-weight: 400;
    font-size: 3.5em;
    padding: 0 10%;
    margin: 64px 0;
  }
  @media screen and (max-width: 450px) {
    .mission-info p {
      font-size: 2em;
    }
  }
  @media screen and (max-width: 385px) {
    .mission-info p {
      font-size: 32px;
    }
  }
  