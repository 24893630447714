
.calendar {
    display: flex;
    flex-wrap: wrap;
    font-family: "Inter", sans-serif;
    position: relative;
  }
  .months {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .month {
    margin: 16px;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
    text-transform: capitalize;
  }
  .month h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 12px;
  }
  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }
  
  .day {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    padding: 9px 5px;
  
  }
  
  .holiday {
    background-color: #f63333;
    color: white;
    border-radius: 50%;
  
  }
  
  .day.disabled {
    color: #b9e0ff; 
  }
  .s-circle-calendar2{
    padding: 7%;
    top: 28%;
    left: -5%;
    z-index: -1;
  }
  @media screen and (max-width: 1405px) {
    .calendar {
      justify-content: center;
    }
    .months {
      justify-content: space-evenly;
    }
  }
  