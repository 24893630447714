.position-careers {
    padding: 40px 0 64px 40px;
    border-radius:40px;
    background-color:#11295A;
    color:white;
    margin: 28px 0;
}
.position-title {
    background-color:#0C5AE9;
    padding: 8px 32px;
    display: inline-block;
    border-radius:65px;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
}
.position-btns {
margin: 64px 0 40px;
}
.position-btns button {
    background-color:white;
    color:#11295A;
    padding: 8px 24px;
    border-radius:8px;
    border-color:transparent;
    margin-right: 16px;
    font-size: 20px;
    font-weight: 700;
}
.position-careers p {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    margin: 40px 0;
}
@media screen and (max-width:632px) {
    .position-careers {
        padding: 32px 16px;
    }
    .position-title {
        width: 100%;
        font-size: 24px;
    }
    .position-btns {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        gap: 2%;
        /* margin: 32px 40px; */
        /* padding: 0; */
    }
    .position-btns button {
        margin-right: 0;
        margin-top: 16px;
    }
    .position-careers p {
        font-size: 18px;
    }

    
}