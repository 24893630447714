/* contact-interested */

.working-together{
    margin: 56px 0;
  }
  .contact-interested {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 4% 0; */
    /* position: relative; */
  }
  
  .contact-address {
    width: 90%;
  }
  .contact-img {
    max-width: 37vw;
    /* height: 100vh; */
    
  }
  /* .contactImg {
    object-fit: contain;
    width: 100%;
    height: 100%;
  
  } */
  
  .contact-address h3 {
    /* line-height: 6.75rem; */
    letter-spacing: -0.03em;
    margin-bottom: 18px;
  }
  
  .contact-location h4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.75rem;
    margin:  18px 0;
    /* color:rgb(234, 232, 232) */
  }
  
  .contact-location h5 {
    font-size: 18px;
    font-weight: 400;
    /* line-height: 1.68rem; */
    /* letter-spacing: -0.25px; */
  }
  
  .contact-socials {
    display: flex;
    gap: 0.52rem;
    /* margin-top: 1.5%; */
  }
  .contact-us {
    /* display: flex;
    align-items:flex-start; */
    /* max-width: 100%; */
  }
  .contact-tel {
    margin-top: 5px;
    /* display: flex; */
    /* align-items:center !important; */
    /* background-color:green; */
    /* height: 50px; */
    width: 100%;
  }
  
  .contact-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(17, 41, 90, 1);
    border-radius: 0.375rem;
    border-color: transparent;
    /* color: #fff; */
    cursor: pointer;
    text-decoration: none;
    padding: 0.7%;
  }
  .contact-logo-big {
    padding: 1.1%;
  }
  .contact-socials {
    margin-top: 5%;
  }
  
  .contact-img img {
    width: 100%;
    height: 80%;
    border-radius: 20px;
    object-fit: cover;
    object-position: center;
  }
  .contact-tel {
    display: flex;
    /* gap: 1%; */
    align-items: flex-start;
    /* line-height: 27px; */
    /* letter-spacing: -0.25px; */
    /* margin-bottom: 1%; */
  }
  .contact-tel h5 {
    font-size: 18px;
    font-weight: 400;
  }
  .contact-interested-bg {
    top: 0;
    padding: 5%;
    top: 50%;
    left: 50%;
    z-index: -1;
  }
  
  @media screen and (max-width: 1000px) {
    .contact-interested {
      display: block;
      padding-top: 25px;
    }
    .contact-img {
      margin-top: 2%;
    }
    .contact-address {
      width: 100%;
      margin-bottom: 78px;
  
    }
    .contact-img {
      max-width: 100vw;
      height: 80vh;
    }
  }
  @media screen and (max-width: 385px) {
    .contact-address h3 {
      line-height: 72px;
    }
  }
  