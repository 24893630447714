.development {
    /* height: 28.19%; */
    /* width: 100%; */
    padding: 64px;
    /* gap: 2rem; */
    border-radius: 2rem;
    opacity: 0px;
    margin: 64px auto !important;
    background: rgba(238, 248, 255, 1);
    position: relative;
    /* margin-top: 50px; */
  }
  
  .development h4 {
    font-size: 48px;
    font-weight: 700;
    color: rgba(17, 41, 90, 1);
  }
  
  .development p {
    font-size: 18px;
    font-weight: 400;
    margin: 16px 0 40px;
  }
  .circle-development {
    top: 15%;
    right: 10%;
    padding: 10%;
  }
  .s-circle-development {
    bottom: 10%;
    padding: 5%;
    right: 20%;
  }
  @media screen and (max-width: 802px) {
    .development {
      /* width: 90%; */
      padding: 48px 16px;
    }
  }
  @media screen and (max-width:725px) {
    .development h4 {
      font-size: 40px;
    }
    
  }
  
  @media screen and (max-width: 615px) {
    .development {
      /* width: 90%; */
      padding: 48px 16px;
    }
    .development h4 {
      font-size: 32px;
      font-weight: 600;
    }
  }
  @media screen and (max-width:480px) {
    .development h4 {
      font-size: 25px;
    }
    
  }
  @media screen and (max-width:375px) {
    .development h4 {
      font-size: 20px;
    }
    
  }
  